import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  InfiniteScrollCustomEvent,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonItem,
  IonLabel,
  IonList,
  IonRefresher,
  IonRefresherContent,
  IonSkeletonText,
  IonThumbnail,
  IonTitle,
  IonToolbar,
  RefresherCustomEvent,
} from '@ionic/angular/standalone';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ModalService } from '@services/modal.service';
import { SocialService } from '@services/social.service';
import { UserItemComponent } from '@shared/components/user-item.component';
import { addIcons } from 'ionicons';
import { closeOutline } from 'ionicons/icons';
import { filter, finalize, tap } from 'rxjs/operators';

import { ISocialLike } from '../shared/models/social-post.model';

@UntilDestroy()
@Component({
  standalone: true,
  imports: [
    CommonModule,
    UserItemComponent,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButtons,
    IonButton,
    IonIcon,
    IonContent,
    IonRefresher,
    IonRefresherContent,
    IonList,
    IonItem,
    IonThumbnail,
    IonSkeletonText,
    IonLabel,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
  ],
  template: `
    <ion-header>
      <ion-toolbar>
        <ion-title>Liked by</ion-title>
        <!-- <ion-badge slot="end">
          {{ total }} {{ total > 1 ? 'like' : 'likes' }}
        </ion-badge> -->
        <ion-buttons slot="end">
          <ion-button (click)="dismiss()" color="dark">
            <ion-icon slot="icon-only" name="close-outline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content role="feed">
      <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <ion-list lines="full">
        <ion-item *ngIf="isLoading">
          <ion-thumbnail style="width: 24px; height: 24px" slot="start">
            <ion-skeleton-text animated></ion-skeleton-text>
          </ion-thumbnail>
          <ion-label>
            <ion-skeleton-text animated style="width: 60%"></ion-skeleton-text>
          </ion-label>
        </ion-item>
        <ion-item color="light" *ngIf="!isLoading && !list.length">
          <ion-label>nobody here</ion-label>
        </ion-item>
        <cs-user-item
          role="article"
          *ngFor="let like of list"
          [user]="like.User"
          [event]="like.CreatedOn"
          (toggleFollow)="toggleFollow(like.User, $event)"
        />
      </ion-list>
      <ion-infinite-scroll
        [disabled]="reachedEnd"
        threshold="100px"
        (ionInfinite)="loadMore($event)"
      >
        <ion-infinite-scroll-content> </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [``],
})
export class SocialLikesModal implements OnInit, OnDestroy {
  @Input() postId: number;
  list: ISocialLike[] = [];
  isLoading = true;
  reachedEnd = false;
  total = 0;

  constructor(
    private modalService: ModalService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private socialPostApiResource: SocialService
  ) {
    addIcons({ closeOutline });
  }

  ngOnInit() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        untilDestroyed(this)
      )
      .subscribe((event) => {
        this.dismiss();
      });
  }

  ngOnDestroy() {}

  ionViewDidEnter(): void {
    this.loadList().subscribe();
  }

  toggleFollow(user: ISocialLike['User'], followed: boolean) {
    user.Followed = followed;
    this.cdr.detectChanges();
  }

  doRefresh(event: RefresherCustomEvent) {
    this.list = [];
    this.reachedEnd = false;
    this.loadList()
      .pipe(finalize(() => event.target.complete()))
      .subscribe();
  }

  loadMore(event?: InfiniteScrollCustomEvent) {
    this.loadList()
      .pipe(finalize(() => event.target.complete()))
      .subscribe();
  }

  loadList() {
    this.isLoading = true;
    this.cdr.detectChanges();

    return this.socialPostApiResource
      .getLikes(this.postId, this.list.length)
      .pipe(
        tap((response) => {
          this.total = Number(response.headers.get('X-Total-Count') ?? '0');
          const list = response.body ?? [];
          this.list = [...this.list, ...list];
          if (this.list.length >= this.total) {
            this.reachedEnd = true;
          }
          this.cdr.detectChanges();
        }),
        finalize(() => {
          this.isLoading = false;
          this.cdr.detectChanges();
        })
      );
  }

  dismiss() {
    this.modalService.dismiss();
  }
}
