import { ellipsisVertical } from 'ionicons/icons';
import { addIcons } from 'ionicons';
import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  IonAvatar,
  IonButton,
  IonIcon,
  IonItem,
  IonLabel,
} from '@ionic/angular/standalone';
import { SocialPostModel } from '@shared/models/social-post.model';
import { UserNamePipe } from '@shared/pipes/user-name.pipe';
import { actionTextSocialPipe } from './action-text.pipe';
import { CreateSourceStandalonePipe } from '@shared/pipes/create-source2.pipe';
import { AgoPipe } from '@shared/pipes/ago.pipe';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    UserNamePipe,
    actionTextSocialPipe,
    CreateSourceStandalonePipe,
    AgoPipe,
    IonItem,
    IonAvatar,
    IonLabel,
    IonButton,
    IonIcon,
  ],
  selector: 'social-post-header',
  template: `
    <ion-item
      mode="ios"
      lines="none"
      [ngStyle]="{
        '--padding-start': mode === 'list' ? '0' : '',
        '--inner-padding-end': mode === 'list' ? '0' : '',
        '--min-height': mode === 'list' ? '0' : '75px'
      }"
      ngsty
      style="
      --background: transparent;
    "
    >
      <ion-avatar
        *ngIf="mode === 'item'"
        class="w-14 h-14 m-0"
        slot="start"
        (click)="avatarClick.emit($event)"
      >
        <img
          loading="lazy"
          *ngIf="post.User.AvatarUrl; else defaultAvatar"
          [src]="post.User.AvatarUrl | createSource"
          src-fallback="assets/images/user-image.png"
        />
        <ng-template #defaultAvatar>
          <img loading="lazy" src="assets/images/user-image.png" />
        </ng-template>
      </ion-avatar>
      <ion-label class="mx-2 ion-text-wrap mt-0">
        <h3 class="line-clamp-2 !overflow-hidden">
          <strong>{{ post.User | userName }} </strong>
          <span class="font-medium" *ngIf="post.Action">{{
            post.Action | actionText
          }}</span>
          <strong *ngIf="post.CigarName">
            {{ ' ' + post.CigarName }}
          </strong>
        </h3>
        <div
          class="m-0 mt-0.5 !text-xs !text-gray-500 dark:!text-gray-300 !font-medium"
        >
          <span *ngIf="mode === 'list'">{{
            post.CreatedOn | ago | async
          }}</span>
          <span *ngIf="mode === 'item'">{{
            post.CreatedOn | date : 'MMM d, y, HH:mm a'
          }}</span>
        </div>

        <div
          *ngIf="post.Location"
          class="w-full inline-flex items-center gap-x-1.5 rounded-full px-1 text-xs font-medium text-red-500"
        >
          <svg
            class="h-1.5 w-1.5 fill-red-500"
            viewBox="0 0 6 6"
            aria-hidden="true"
          >
            <circle cx="3" cy="3" r="3" />
          </svg>
          <div class="truncate">
            {{ post.Location }}
          </div>
        </div>
      </ion-label>
      <div class="m-0" slot="end">
        <ion-button
          *ngIf="isOwner"
          (click)="ownerPopover.emit($event)"
          fill="clear"
          color="dark"
          size="small"
        >
          <ion-icon slot="icon-only" name="ellipsis-vertical"></ion-icon>
        </ion-button>
        <ion-button
          *ngIf="!isOwner"
          (click)="reportPopover.emit($event)"
          fill="clear"
          color="dark"
          size="small"
        >
          <ion-icon slot="icon-only" name="ellipsis-vertical"></ion-icon>
        </ion-button>
      </div>
    </ion-item>
  `,
  styles: [
    `
      :host {
        display: block;
      }
    `,
  ],
})
export class SocialPostHeaderComponent implements OnInit {
  @Input() post: SocialPostModel;
  @Input() mode: 'list' | 'item' = 'item';
  @Input() isOwner = false;
  @Output() avatarClick = new EventEmitter<Event>();
  @Output() ownerPopover = new EventEmitter<Event>();
  @Output() reportPopover = new EventEmitter<Event>();
  constructor() {
    addIcons({
      ellipsisVertical,
    });
  }

  ngOnInit() {}
}
