import { Injectable } from '@angular/core';
import { Share } from '@capacitor/share';
import { environment } from '@environments/environment';
import { ICigar } from '@models';
import { SocialPostModel } from '@shared/models/social-post.model';
import { getCigarCreatedId } from '@utils/cigar';
import { AppRoutes } from '@utils/routes';
import debug from 'debug';
import { AnalyticsService } from './analytics.service';

const log = debug('cs:ShareService');
@Injectable({
  providedIn: 'root',
})
export class ShareService {
  public canShare = false;
  constructor(private analyticsService: AnalyticsService) {
    Share.canShare().then((canShare) => {
      this.canShare = canShare.value;
    });
  }

  async sharePost(post: SocialPostModel) {
    try {
      await Share.share({
        url: `${environment.appUrl}${AppRoutes.socialPost(post.Id)}`,
      });
      this.analyticsService.socialShare();
    } catch (error) {
      log({ error });
    }
  }

  async shareProduct(product: ICigar) {
    const hashTagName = this.hashTag(product.Name);
    const hashTagManufacturer = this.hashTag(product.Attributes.Manufacturer);
    let canonical;
    if (product.CanonicalUrl && product.CanonicalUrl.startsWith('//')) {
      canonical = 'https://' + product.CanonicalUrl.substring(2);
    }
    try {
      await Share.share({
        title: `Enjoying a ${product.Name} cigar!`,
        text: `Enjoying a ${product.Name} cigar! #CigarScanner ${hashTagName}${hashTagManufacturer}`,
        url: canonical
          ? canonical
          : `${environment.appUrl}/tabs/social/cigar/${getCigarCreatedId(
              product
            )}`,
        dialogTitle: `Share ${product.Name}`,
      });
    } catch (error) {
      log({ error });
    }
  }

  hashTag(words) {
    if (words && typeof words === 'string') {
      words = words.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
        if (+match === 0) {
          return '';
        }
        return index === 0 ? match.toLowerCase() : match.toUpperCase();
      });
      return '#' + words.charAt(0).toUpperCase() + words.slice(1) + ' ';
    } else {
      return '';
    }
  }
}
