import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import {
  PopoverController,
  NavParams,
  IonList,
  IonItem,
} from '@ionic/angular/standalone';
import { ReportReasonModel } from '@shared/models/report-reason.model';

@Component({
  standalone: true,
  imports: [CommonModule, IonList, IonItem],
  template: `
    <ion-list lines="none" class="pop-over-list">
      <ion-item *ngFor="let reason of reportReasons" (click)="report(reason)">
        {{ reason.Title }}
      </ion-item>
    </ion-list>
  `,
})
export class ReportPopover {
  reportReasons: ReportReasonModel[];

  constructor(
    private popoverCtrl: PopoverController,
    public params: NavParams
  ) {}

  ngOnInit() {
    this.reportReasons = this.params.get('reasons');
  }

  close() {
    this.popoverCtrl.dismiss();
  }

  report(type) {
    this.params.get('showConfirm')(type);
    this.popoverCtrl.dismiss();
  }
}
