<div class="space-y-2 pb-[2px] mt-2">
  <social-post-header
    [post]="post"
    [isOwner]="isOwner"
    (avatarClick)="navigateToUserProfile($event, post.User)"
    (ownerPopover)="presentOwnerPopover($event, post)"
    (reportPopover)="reportPopover($event)"
  ></social-post-header>
  <div class="px-3">
    <!-- <div *ngIf="mode === 'list'">
      <ion-avatar
        class="w-10 h-10 mt-2"
        slot="start"
        (click)="navigateToUserProfile($event, post.User)"
      >
        <img
          loading="lazy"
          *ngIf="post.User.AvatarUrl; else defaultAvatar"
          [src]="post.User.AvatarUrl | createSource"
          src-fallback="assets/images/user-image.png"
        />
        <ng-template #defaultAvatar>
          <img loading="lazy" src="assets/images/user-image.png" />
        </ng-template>
      </ion-avatar>
    </div> -->
    <div class="w-full space-y-1">
      <!-- <social-post-header
        [post]="post"
        [mode]="mode"
        [isOwner]="isOwner"
        (avatarClick)="navigateToUserProfile($event, post.User)"
        (ownerPopover)="presentOwnerPopover($event, post)"
        (reportPopover)="reportPopover($event)"
      ></social-post-header> -->
      <cigar-review
        *ngIf="post.ReviewInfo?.Rating"
        class="text-sm"
        [review]="post.ReviewInfo"
      ></cigar-review>
      <p
        class="mt-0 text-sm font-normal prose whitespace-pre-line max-w-none dark:text-white"
        *ngIf="post.Text"
      >
        <cs-text-expand
          *ngIf="mode === 'list'; else text"
          [text]="post.Text"
          [maxLength]="600"
        ></cs-text-expand>
        <ng-template #text>{{ post.Text }}</ng-template>
      </p>
      <div class="max-w-md m-auto" *ngIf="post.ImageUrl">
        <div
          class="relative w-full overflow-hidden bg-white rounded-lg min-h-80 aspect-square group-hover:opacity-75"
        >
          <img
            loading="lazy"
            [src]="post.ImageUrl | createSource"
            alt="Two models wearing women's black cotton crewneck tee and off-white cotton crewneck tee."
            class="object-cover object-center w-full h-full"
            (click)="openImageModal($event, post.ImageUrl)"
          />
        </div>
      </div>
      <div
        class="py-2 rounded-lg bg-neutral-50 dark:bg-neutral-800"
        *ngIf="!hideCigar && post.CigarRating && post.CigarName"
      >
        <cigar-info
          [cigar]="post"
          [name]="post.CigarName"
          [price]="post.CigarPrices"
          [rating]="post.CigarRating"
          [prices]="post.CigarPrices"
          (cigarClicked)="navigateToProduct.emit(post)"
          (addToListsClicked)="addToList($event)"
          (addToHumidorsClicked)="addToHumidors()"
        >
        </cigar-info>
      </div>
    </div>
  </div>
  <div
    class="divide-x-0 divide-y divide-gray-300 dark:divide-gray-700 divide-solid"
  >
    <div
      class="flex justify-between px-3 align-middle"
      *ngIf="mode === 'item' && post.Likes > 0"
    >
      <ion-button
        fill="clear"
        expand="full"
        color="medium"
        size="small"
        (click)="openLikesModal($event, post)"
        *ngIf="post.Likes > 0"
      >
        {{ post.Likes }}
        <span class="ml-1" [ngPlural]="post.Likes">
          <ng-template ngPluralCase="=0">like</ng-template>
          <ng-template ngPluralCase="=1">like</ng-template>
          <ng-template ngPluralCase="other">likes</ng-template>
        </span>
      </ion-button>
      <!-- <ion-button
        fill="clear"
        expand="full"
        color="medium"
        size="small"
        [disabled]="hideCommentsModal"
        *ngIf="post.Comments > 0"
        (click)="goToComments($event)"
      >
        {{ post.Comments }}
        <span class="ml-1" [ngPlural]="post.Comments">
          <ng-template ngPluralCase="=0">comment</ng-template>
          <ng-template ngPluralCase="=1">comment</ng-template>
          <ng-template ngPluralCase="other">comments</ng-template>
        </span>
      </ion-button> -->
    </div>
    <div
      class="px-3 flex py-[2px] gap-2 border-0 border-t border-solid border-gray-300 dark:border-gray-700"
    >
      <ion-button
        mode="md"
        fill="clear"
        color="medium"
        size="medium"
        [disabled]="isCommentLoading"
        (click)="openCommentModalClick($event, post)"
      >
        <ion-icon slot="icon-only" name="chatbubble-outline"></ion-icon>
        <span class="ml-1" *ngIf="post.Comments">
          {{ post.Comments | numeral }}
        </span>
      </ion-button>
      <ion-button
        mode="md"
        fill="clear"
        [color]="post.Liked ? 'primary' : 'medium'"
        [disabled]="isLikeLoading"
        (click)="toggleLike($event, post)"
        size="medium"
      >
        <ion-icon
          style="font-size: 25px"
          slot="icon-only"
          [name]="post.Liked ? 'heart' : 'heart-outline'"
        ></ion-icon>
        <span class="ml-1" *ngIf="mode === 'list' && post.Likes">
          {{ post.Likes | numeral }}
        </span>
      </ion-button>
      <!-- <ion-button
        *ngIf="!isOwner"
        fill="clear"
        expand="full"
        [color]="post.User.Followed ? 'primary' : 'medium'"
        [disabled]="isFollowLoading"
        (click)="toggleFollowUser($event, post)"
        size="small"
      >
        <ion-icon
          slot="start"
          [name]="
            post.User.Followed ? 'person-circle' : 'person-circle-outline'
          "
        ></ion-icon>
        FOLLOW
      </ion-button> -->
      <span class="flex-1"></span>
      <ion-button
        mode="md"
        *ngIf="shareService.canShare"
        fill="clear"
        color="medium"
        (click)="sharePost($event, post)"
        size="medium"
      >
        <ion-icon slot="icon-only" name="share-social-outline"></ion-icon>
      </ion-button>
    </div>
    <!-- <social-post-skeleton-comment
      *ngIf="areCommentsLoading"
    ></social-post-skeleton-comment>
    <ul
      *ngIf="!areCommentsLoading && comments.length"
      class="p-0 m-0 overflow-hidden list-none"
    >
      <social-post-comment-container
        *ngFor="
          let comment of comments | slice : 0 : maxCommentLength;
          let i = index;
          let last = last;
          trackBy: trackByCommendId
        "
        [comment]="comment"
        [postAuthor]="post.User"
        [isHighlighted]="highlightedComment === comment.Id"
        [highlightedComment]="highlightedComment"
        [showMenu]="hideCommentsModal"
        [hasMore]="comment.ThreadComments?.length > 0"
        (reply)="openCommentReplyModal($event, post.User)"
        (commentClick)="!hideCommentsModal && openCommentModal(post)"
      >
      </social-post-comment-container>
    </ul> -->
  </div>
</div>
<ng-content></ng-content>
